<template>
	<div>
		<q-file
			v-if="file && file.file_name"
			v-model="import_file"
			:hint="`Last imported file: ${file.file_name}`"
			color="primary"
			label="Select a .csv comma delimited file to import"
			class="q-my-sm"
			accept=".csv"
			bottom-slots
			outlined
			clearable
		>
			<template v-slot:prepend>
				<q-icon name="file_upload" @click.stop />
			</template>
		</q-file>
		<q-file
			v-else
			v-model="import_file"
			color="primary"
			label="Select a .csv comma delimited file to import"
			class="q-my-sm"
			accept=".csv"
			outlined
		>
			<template v-slot:prepend>
				<q-icon name="file_upload" @click.stop />
			</template>
			<template v-if="import_file" v-slot:append>
				<q-icon name="cancel" @click.stop="cancel" class="cursor-pointer" />
			</template>
		</q-file>
		<div v-if="file">
			<div class="q-my-md">
				<strong>How many datasets are you importing to?</strong>
				<div class="row">
					<div class="col-12 col-sm-6 q-my-sm">
						<q-option-group
							:options="import_by_options"
							:disable="
								import_type &&
								import_type.label.toLowerCase() === 'delete these users only'
							"
							v-model="import_by_value"
							color="primary"
						/>
					</div>
					<div class="col-12 col-sm-6 q-my-sm flex items-center">
						<q-select
							v-if="import_by_value === 'single'"
							v-model="selected_import_by_single"
							:options="cg_options"
							:disable="
								import_type &&
								import_type.label.toLowerCase() === 'delete these users only'
							"
							:error="selected_import_by_single_error"
							:error-message="required_error_msg"
							ref="selected_import_by_single"
							class="full-width"
							label="Dataset"
							name="dataset"
							hint="Select dataset"
							outlined
							bottom-slots
						/>
						<q-select
							v-if="import_by_value === 'multi'"
							v-model="selected_import_by_multi"
							:options="column_options"
							:error="selected_import_by_multi_error"
							:error-message="required_error_msg"
							ref="selected_import_by_multi"
							class="full-width"
							label="Column"
							name="column"
							hint="Select column"
							outlined
							bottom-slots
						/>
					</div>
				</div>
			</div>
			<div class="q-my-md">
				<q-select
					outlined
					v-model="import_type"
					:options="import_type_options"
					:error="import_type_error"
					:error-message="required_error_msg"
					ref="import_type"
					label="Import type"
					name="import-type"
					hint="Which import type are you using?"
					bottom-slots
				/>
			</div>
			<div class="q-my-sm">
				<div class="q-pa-md">
					<q-checkbox
						v-model="keep_admins_from_demoting"
						label="Prevent Admins from being demoted to a lower permission level via import"
					/>
					<p
						class="text-negative q-pa-sm warning-overlay danger-overlay q-mt-sm q-mb-none"
						v-if="!keep_admins_from_demoting"
					>
						<q-icon name="warning" /> WARNING - admins can be demoted via import
					</p>
				</div>
				<q-separator />
				<div v-if="import_by_value === 'single' && is_root_cg" class="q-pa-md">
					<q-checkbox
						v-model="allow_new_field_options_to_be_created"
						label="Allow new Data field options to be created via import"
					/>
					<p
						class="text-warning q-pa-sm warning-overlay q-mt-sm q-mb-none"
						v-if="allow_new_field_options_to_be_created"
					>
						<q-icon name="warning" />WARNING - New data field options may be
						created via import
					</p>
				</div>
				<q-separator v-if="import_by_value === 'single'" />
			</div>

			<div class="q-pa-sm q-my-sm" style="max-height: 500px; overflow-y: auto;">
				<div class="row justify-between q-pa-xs items-center file-header">
					<div class="col-4">
						<b>Mapped fields</b>
					</div>
					<div class="col-4">
						<b>Sample data from file</b>
					</div>
				</div>
				<div class="row justify-between q-pa-xs items-center"
					v-for="(col, index) in table_cols"
					:ref="`vertical-col-${index}`"
					:key="index"
					:class="{'bg-grey-3': index % 2 == 0}"
				>
					<div
						class="col-4"
					>
						<q-group-select
							v-if="!table_cols_vals[index]"
							v-model="table_cols_vals[index]"
							:options="table_column_options"
							:error="!table_cols_vals[index]"
							:label="file.file_headers[index]"
							:disable="index === disabled_column_index"
							error-message="There is no match for this column."
							style="min-width: 250px"
							bottom-slots
							dense
							borderless
						/>
						<q-group-select
							v-else-if="table_cols_vals[index].value === -1"
							v-model="table_cols_vals[index]"
							:options="table_column_options"
							:hint="file.file_headers[index]"
							:disable="index === disabled_column_index"
							style="min-width: 250px"
							borderless
							dense
							bottom-slots
						/>
						<q-group-select
							v-else
							v-model="table_cols_vals[index]"
							:options="table_column_options"
							:disable="index === disabled_column_index"
							style="min-width: 250px"
							dense
							borderless
						/>
					</div>
					<div class="col-4 items-center text-grey-7" v-if="table_rows.length">
						{{table_rows[0][col.field]}}
					</div>
				</div>
			</div>
		</div>
		<div v-if="!hide_action_buttons" class="flex justify-end">
			<q-btn
				v-if="manual_import"
				outline
				color="primary"
				class="q-mr-md"
				icon-right="upload_file"
				label="Import"
				@click="saveSettings(false)"
			/>
			<q-btn
				v-if="manual_import && is_root_cg"
				outline
				color="primary"
				class="q-mr-md"
				icon-right="save"
				label="Save"
				@click="saveSettings(true)"
			/>
			<q-btn
				v-if="file && !manual_import"
				outline
				color="primary"
				class="q-mr-md"
				icon-right="save"
				label="Save"
				@click="saveSettings"
			/>
			<q-btn
				v-if="delete_button"
				outline
				color="primary"
				class="q-mr-md"
				icon-right="delete"
				label="Delete"
				@click="$emit('delete')"
			/>
			<q-btn
				v-else
				outline
				color="primary"
				class="q-mr-md"
				icon-right="cancel"
				label="Cancel"
				@click="cancel"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import QGroupSelect from '@/components/utils/QGroupSelect.vue'

export default {
	name: 'ImportSettings',
	components: {
		QGroupSelect,
	},
	props: {
		import_settings: {
			required: true,
			type: Object,
		},
		file: Object,
		table_column_options: Array,
		delete_button: Boolean,
		manual_import: Boolean,
		hide_action_buttons: Boolean
	},
	data() {
		return {
			import_file: null,
			table_cols_vals: [],
			disabled_column_index: null,
			selected_import_by_single_error: false,
			selected_import_by_multi_error: false,
			import_type_error: false,
			required_error_msg: 'This field is required.',
			import_by_options: [
				{
					label: 'A Single Dataset',
					value: 'single',
				},
				{
					label: 'Multiple Datasets based on a column in my import file',
					value: 'multi',
				},
			],
			user_set_import_by_value: false,
			import_type_options: [
				{ label: 'Add new and update existing', value: 'update_import' },
				{
					label:
						'Add new, update existing and delete any users not listed in import file',
					value: 'full_import',
				},
				{ label: 'Delete these users only', value: 'delete_only_import' },
			],
		}
	},
	computed: {
		...mapGetters(['customer_groups', 'logged_user', 'is_root_cg']),
		ignore_option() {
			let ignore_option = null
			this.table_column_options.forEach((group) => {
				group.options.filter((o) => {
					if (o.label.toLowerCase() === 'ignore') {
						ignore_option = o
					}
				})
			})
			return ignore_option
		},
		cg_options() {
			return this.customer_groups.map((item) => {
				return {
					value: item.id,
					label: item.name,
				}
			})
		},
		table_rows() {
			let rows = this.file.file_data.map((row) => {
				let mapped = {}
				row.forEach((col, index) => {
					mapped[this.file.file_headers[index]] = col
				})
				return mapped
			})
			return rows
		},
		column_options() {
			let system_fields = [
				'Date of registration',
				'Last Login',
				'Last Password Change',
				'Consent confirmation',
				'Last Login Attempt',
				'External_ID',
				'Work Email',
				'Is Self Registered',
			]
			return this.file.file_headers
				.map((header, index) => {
					let obj = {
						label: header,
						value: index.toString(),
					}
					return obj
				})
				.filter((header) => !system_fields.includes(header.label))
		},
		import_by_value: {
			get() {
				if (this.user_set_import_by_value) {
					return this.user_set_import_by_value
				}
				if (this.import_settings.import_by_column) {
					return 'multi'
				} else {
					return 'single'
				}
			},
			set(val) {
				if (val === 'multi'){
					this.import_settings.allow_new_field_options_to_be_created = false
				}
				this.user_set_import_by_value = val
			},
		},
		selected_import_by_single: {
			get() {
				return (
					this.$store.getters.customer_groups
						.filter(
							(cg) => cg.id === parseInt(this.import_settings.customer_group)
						)
						.map((cg) => {
							return {
								label: cg.name,
								value: cg.id,
							}
						})[0] || null
				)
			},
			set(option) {
				// Clean the disabled column if there is one
				this.disabled_column_index = null
				this.selected_import_by_single_error = false
				this.update({
					property: 'customer_group',
					value: option.value.toString(),
				})
			},
		},
		selected_import_by_multi: {
			get() {
				let column =
					this.file.file_headers[
						parseInt(this.import_settings.import_by_column)
					]
				column = {
					label: column,
					value: this.import_settings.import_by_column,
				}
				return column.value ? column : null
			},
			set(option) {
				this.selected_import_by_multi_error = false
				this.update({
					property: 'import_by_column',
					value: option.value.toString(),
				})
			},
		},
		import_type: {
			get() {
				return this.import_type_options.filter(
					(option) => option.value === this.import_settings.import_type
				)[0]
			},
			set(option) {
				this.import_type_error = false
				this.update({
					property: 'import_type',
					value: option.value,
				})
			},
		},
		keep_admins_from_demoting: {
			get() {
				return this.import_settings.keep_admins_from_demoting.toLowerCase() ===
					'true'
					? true
					: false
			},
			set(val) {
				this.update({
					property: 'keep_admins_from_demoting',
					value: val.toString(),
				})
			},
		},
		allow_new_field_options_to_be_created: {
			get() {
				return this.import_settings.allow_new_field_options_to_be_created
			},
			set(val) {
				this.update({
					property: 'allow_new_field_options_to_be_created',
					value: val,
				})
			},
		},
	},
	methods: {
		disableColumn(option) {
			this.table_cols_vals.filter((col, index) => {
				if (index === parseInt(option.value)) {
					this.disabled_column_index = index
					this.table_cols_vals[index] = this.ignore_option
				}
			})
		},
		formatColumnName(name) {
			name = name.toLowerCase().trim()
			name = name.split(' ').join('-')
			name = name.split('_').join('-')
			let splitted_name = []
			if (name[0] === '(') {
				splitted_name = name.split('-')
				if (splitted_name[0].startsWith('(') && splitted_name[0].endsWith(')')) {
					splitted_name.shift()
					splitted_name = splitted_name.join('-')
				}
				name = splitted_name
			}
			return name
		},
		update(obj) {
			let data = JSON.parse(JSON.stringify(this.import_settings))
			data[obj.property] = obj.value
			if (obj.property === 'import_by_column') {
				data.customer_group = ''
			} else if (obj.property === 'customer_group') {
				data.import_by_column = ''
			}
			if (data.import_type === 'delete_only_import') {
				this.disabled_column_index = null
				data.import_by_column = ''
				data.customer_group = this.logged_user.customer_group.toString()
				this.user_set_import_by_value = false
			}
			this.$emit('update', data)
		},
		initTableData(file) {
			this.table_cols_vals = []
			this.table_cols = file.file_headers.map((header, index) => {
				return {
					name: header + Math.random().toString(16).slice(2),
					field: header,
					label: header,
					clean_name: header,
					align: 'left',
				}
			})
			let joined_group_options = []
			this.table_column_options.map((opt_group) => {
				joined_group_options = [...joined_group_options, ...opt_group.options]
			})
			if (!this.import_settings.rules) {
				this.table_cols_vals = this.table_cols.map((col) => {
					let valObj = null
					joined_group_options.forEach((option) => {
						if (
							this.formatColumnName(option.label) ===
							this.formatColumnName(col.clean_name)
						) {
							valObj = {
								value: option.value,
								label: option.label,
							}
						}
					})
					let ignore_fields = [
						'Last Login',
						'Date of registration',
						'Last Password Change',
						'Is Self Registered',
						'Last Login Attempt',
						'Consent confirmation',
					]
					if (ignore_fields.includes(col.clean_name)) {
						valObj = { value: -1, label: 'Ignore' }
					}
					return valObj
				})
			} else {
				let rules = this.import_settings.rules
				if (typeof rules == 'string') {
					rules = JSON.parse(this.import_settings.rules)
				}
				for (const key in rules) {
					this.table_column_options.forEach((group) => {
						group.options.filter((o) => {
							if (o.value == rules[key]) {
								this.table_cols_vals.push({
									value: o.value,
									label: o.label,
								})
							}
						})
					})
				}
			}
			// Re-set the disabled column
			if (this.disabled_column_index !== null) {
				this.table_cols_vals[this.disabled_column_index] = this.ignore_option
			}
			if (
				this.import_type &&
				this.import_type.label === 'Delete these users only'
			) {
				this.table_cols_vals = this.table_cols_vals.map((col) => {
					if (!col || (col.label.toLowerCase() !== 'work email' && col.label.toLowerCase() !== 'external id')) {
						return this.ignore_option
					}
					return col
				})
			}
		},
		buildRules() {
			let column_list = this.table_cols_vals.map((col) => {
				if (!col) {
					return ''
				}
				return parseInt(col.value)
			})
			return column_list
		},
		scrollToElement(el, scrolled) {
			if (!scrolled) {
				window.scroll({
					top: el.offsetTop,
					behavior: 'smooth',
				})
			}
		},
		validateImportSettings() {
			let valid = true
			let scrolled = false
			let import_by_val =
				this.import_by_value === 'single'
					? this.selected_import_by_single
					: this.selected_import_by_multi
			let import_select_name = `selected_import_by_${this.import_by_value}`
			if (!import_by_val) {
				this[`${import_select_name}_error`] = true
				valid = false
				this.scrollToElement(this.$refs[import_select_name].$el, scrolled)
				scrolled = true
			}
			if (!this.import_type) {
				valid = false
				this.import_type_error = true
				this.scrollToElement(this.$refs.import_type.$el, scrolled)
				scrolled = true
			}
			let first_invalid_col_index = null
			for (let i = 0; i < this.table_cols_vals.length; i++) {
				if (!this.table_cols_vals[i]) {
					first_invalid_col_index = i
					break
				}
			}
			if (first_invalid_col_index) {
				valid = false
				if (!scrolled) {
					this.$refs[`vertical-col-${first_invalid_col_index}`][0]
						.scrollIntoView({behavior:'smooth', block: 'start'})
				}
				scrolled = true
			}
			return valid
		},
		saveSettings(is_template) {
			let rules = this.buildRules()
			let emit_func = 'save'
			if (this.manual_import){
				if (is_template){
					emit_func = 'saveTemplate'
				} else {
					emit_func = 'import'
				}
			}

			if (this.validateImportSettings()) {
				this.$emit(emit_func, rules)
			}
		},
		cancel() {
			this.import_file = null
			this.$emit('cancel')
		}
	},
	created() {
		if (this.file) {
			this.initTableData(this.file)
		}
		if (this.import_by_value === 'multi') {
			this.disableColumn(this.selected_import_by_multi)
		}
	},
	watch: {
		import_file: {
			async handler(val) {
				await this.$emit('fileUpdate', val)
			},
			deep: true,
		},
		file: {
			handler(val) {
				if(val){
					this.initTableData(val)
				}
			},
			deep: true,
		},
		'import_settings.import_by_column': {
			handler(import_by_column){
				if(this.file) {
					if (import_by_column || import_by_column != 0){
						let column = this.file.file_headers[parseInt(import_by_column)]
						column = {
							label: column,
							value: import_by_column,
						}
						this.disableColumn(column)
					}
				}
			},
			deep: true
		}
	},
}
</script>

<style lang="scss" scoped>
.warning-overlay {
	position: relative;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $warning;
		opacity: 0.15;
		border-radius: 2px;
	}
	color: $warning;
}
.danger-overlay {
	&::before {
		background-color: $negative;
	}
	color: $negative;
}

.file-header {
	font-size: 15px;
	border-bottom: 3px double $grey-5
}
</style>
