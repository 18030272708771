<template>
	<q-select v-bind="$attrs">
		<template v-slot:option="scope">
			<q-item v-if="scope.opt.name" class="text-primary text-weight-medium">
				<q-item-section>{{ scope.opt.name }}</q-item-section>
			</q-item>
			<q-item
				v-for="option in scope.opt.options"
				:key="option.value"
				clickable
				v-ripple
				v-close-popup
				:active="option.value === val"
				@click="$emit('input', option)"
			>
				<q-item-section>
					<q-item-label class="q-ml-md">{{ option.label }}</q-item-label>
				</q-item-section>
			</q-item>
		</template>
	</q-select>
</template>

<script>
export default {
	name: 'QGroupSelect',
	computed: {
		val() {
			if (!this.$attrs.value) {
				return null
			}
			return this.$attrs.value.value
		},
	},
}
</script>

<style></style>
